import React, {useState} from 'react';
import {CContainer, CRow, CCol, CButton} from '@coreui/react';
import VerticalNavbar from '../components/VerticalNavbar';
import AssistantResponse from '../components/AssistantResponse';
import AssistantSearchInput from "../components/AssistantSearchInput";
import FileInput from "../components/FileInput";
import leagleClient from "../client/LeagleClient";

const AssistantView = () => {
    const [prompt, setPrompt] = useState('');
    const [assistantResponse, setAssistantResponse] = useState('');
    const [responseState, setResponseState] = useState('');
    const [task, setTask] = useState('draft_contract');
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [widthVal, setWidthVal] = useState("250px");
    const widthValNumber = parseInt(widthVal);
    const widthValNumberLast = widthValNumber+10;
    const [isSessionComplete, setIsSessionComplete] = useState(false);
    const [documentData, setDocumentData] = useState(null);
    const [templateDocName, setTemplateDocName] = useState(null);
    const [templateDoc, setTemplateDoc] = useState(null);

    const handleAssistantSearch = async () => {
        setIsSessionComplete(true);
        setIsLoading(true);
        setAssistantResponse(''); // Reset response
        setResponseState(''); // Reset state
        setDocumentData(null); // Reset document data
    
        const formData = new FormData();
        formData.append('prompt', prompt);
        formData.append('task', task);
        formData.append('template', templateDocName);
        formData.append(templateDoc.name, templateDoc);
        uploadedFiles.forEach(file => {
            formData.append(file.name, file);
        });
    
        let processedIndex = 0; // Cursor to track processed text
    
        try {
            const response = await leagleClient.post('/chat/leagle-assistant/', formData, {
                responseType: 'text',
                onDownloadProgress: (progressEvent) => {
                    const text = progressEvent.event.target.responseText;
                    // Extract the new unprocessed portion of the text
                    const newText = text.slice(processedIndex);
                    processedIndex = text.length; // Update cursor
                
                    // Match JSON objects and separate raw text
                    const regex = /({.*?})/g; // Match JSON objects like {"state": "..."} or {"document": "..."}
                    let lastIndex = 0;
                
                    let match;
                    while ((match = regex.exec(newText)) !== null) {
                        // Append any raw text before the JSON object
                        const rawText = newText.slice(lastIndex, match.index);
                        if (rawText.trim()) {
                            setAssistantResponse(prev => prev + rawText);
                        }
                
                        // Parse the JSON object
                        try {
                            const jsonString = match[1];
                            const parsedJSON = JSON.parse(jsonString);
                            if (parsedJSON.state) {
                                setResponseState(parsedJSON.state);
                            }
                        } catch (error) {
                            console.error("Failed to parse JSON:", match[1]);
                        }
                
                        lastIndex = regex.lastIndex; // Update the last index
                    }
                
                    // Append any remaining raw text after the last JSON object
                    const remainingText = newText.slice(lastIndex);
                    if (remainingText.trim()) {
                        setAssistantResponse(prev => prev + remainingText);
                    }
                }
                
            });
    
        } catch (error) {
            console.error('Error during API request:', error);
        } finally {
            setIsLoading(false);
        }
    };
    
    const createNewSession = ()=>{
        window.location.reload()
    }

    const handleDownload = () => {
        if (documentData) {
            const blob = new Blob([atob(documentData)], { type: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = 'generated_document.docx';
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            document.body.removeChild(a);
        }
    };

    return(
        <div className="full-height">
            <div className="h-100">
                <VerticalNavbar
                    widthVal={widthVal}
                    setWidthVal={setWidthVal}
                />
                <div
                    style={{
                        padding: `10px 35px 0px ${widthValNumberLast}px`,
                        transition: "padding-left 0.4s ease",
                        overflow:'hidden',
                        height:'100%'
                    }}
                >
                    <CContainer fluid className="h-100">
                        <CRow className="h-100" >
                            <CCol className="d-flex flex-column h-100" style={{padding:'2%'}}>
                                <AssistantSearchInput prompt={prompt} setPrompt={setPrompt} task={task} setTask={setTask} templateDocName={templateDocName} setTemplateDocName={setTemplateDocName} templateDoc={templateDoc} setTemplateDoc={setTemplateDoc}/>
                                <hr className="solid"></hr>
                                <FileInput uploadedFiles={uploadedFiles} setUploadedFiles={setUploadedFiles} />
                                    {
                                        (!isSessionComplete) ?
                                            <CButton size="lg" color="primary" onClick={() => handleAssistantSearch()}>Leagle'a sor...</CButton>
                                            :
                                                <CButton size="lg" color="secondary" onClick={() => createNewSession()}>Yeni Konu</CButton>
                                }
                            </CCol>
                            <CCol className="d-flex flex-column h-100" style={{padding:'2%'}}>
                                <h2 style={{ marginBottom: '10px'}}>Durum: <i style={{fontSize: '1em'}}>{responseState}</i></h2>
                                <hr className="solid"></hr>
                                <AssistantResponse isLoading={isLoading} response={assistantResponse}/>
                            </CCol>
                        </CRow>
                    </CContainer>
                </div>
            </div>
        </div>
    )
};

export default AssistantView;