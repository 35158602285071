import React from 'react';
import {
    CCard,
    CCardHeader,
    CCardBody,
    CTable,
    CTableHead,
    CTableRow,
    CTableHeaderCell,
    CTableBody,
    CTableDataCell,
    CBadge,
    CButton,
    CRow,
    CCol,
} from '@coreui/react';
import {SubscriptionDetailsResponse} from "../model/response/subscriptionDetailsResponse";

interface SubscriptionDetailsProps {
    subscriptionDetails: SubscriptionDetailsResponse;
    onCancelSubscription: () => void;
    setIsUpgradeSubscriptionPopupVisible: (value: boolean) => void;
}

interface SubscriptionField {
    label: string;
    value: string;
    isBadge?: boolean;
}

const SubscriptionDetails: React.FC<SubscriptionDetailsProps> = ({ subscriptionDetails, onCancelSubscription, setIsUpgradeSubscriptionPopupVisible }) => {
    const formatDate = (timestamp: number | undefined): string => {
        if (!timestamp) return '-';
        return new Date(timestamp).toLocaleDateString('tr-TR', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    };

    const formatPrice = (price: number, currencyCode: string): string => {
        return new Intl.NumberFormat('tr-TR', {
            style: 'currency',
            currency: currencyCode
        }).format(price);
    };

    const getStatusColor = (status: string): string => {
        const colors = {
            ACTIVE: 'success',
            CANCELLED: 'danger',
            TRIAL: 'info',
            EXPIRED: 'secondary',
        };
        return colors[status] || 'secondary';
    };

    const currentOrder = subscriptionDetails.orders?.[0];

    const subscriptionFields: SubscriptionField[] = [
        {
            label: 'Abonelik Planı',
            value: subscriptionDetails.subscriptionTierName
        },
        {
            label: 'Durum',
            value: subscriptionDetails.subscriptionStatus,
            isBadge: true
        },
        currentOrder ? {
            label: 'Geçerlilik Süresi',
            value: `${formatDate(currentOrder.startPeriod)} - ${formatDate(currentOrder.endPeriod)}`
        } : null,
        currentOrder ? {
            label: 'Güncel Ödeme',
            value: formatPrice(currentOrder.price, currentOrder.currencyCode)
        } : null,
        subscriptionDetails.trialDays ? {
            label: 'Deneme Süresi',
            value: `${subscriptionDetails?.trialDays} gün`
        } : null,
        subscriptionDetails.trialStartDate ? {
            label: 'Deneme Başlangıç',
            value: formatDate(subscriptionDetails?.trialStartDate)
        } : null,
        subscriptionDetails.trialEndDate ? {
            label: 'Deneme Bitiş',
            value: formatDate(subscriptionDetails.trialEndDate)
        }: null
    ].filter((field): field is SubscriptionField => field !== null);

    return (
        <div className="mt-4">
            {/* Current Subscription Card */}
            <CCard className="border-0 shadow-sm">
                <CCardHeader className="bg-white border-bottom py-3">
                    <h2 className="m-0 fs-4" style={{ color: "#555" }}>
                        Abonelik Bilgileri
                    </h2>
                </CCardHeader>
                <CCardBody className="p-4">
                    <div className="d-flex flex-column gap-3">
                        {subscriptionFields.map((field, index) => (
                            <CRow key={index} className="align-items-center py-2 border-bottom border-light">
                                <CCol xs={12} sm={6}>
                                    <span className="text-secondary">{field.label}:</span>
                                </CCol>
                                <CCol xs={12} sm={6} className="text-sm-end">
                                    {field.isBadge ? (
                                        <CBadge
                                            color={getStatusColor(field.value)}
                                            className="px-3 py-2"
                                            style={{ fontSize: '0.85rem' }}
                                        >
                                            {field.value}
                                        </CBadge>
                                    ) : (
                                        <span className="fw-medium">{field.value}</span>
                                    )}
                                </CCol>
                            </CRow>
                        ))}
                    </div>

                    {sessionStorage.getItem('subscriptionTier') != 'EXEMPT' && (
                        <CButton
                                color="info"
                                className="w-100 mt-4 py-3"
                                onClick={() => setIsUpgradeSubscriptionPopupVisible(true)}
                                style={{
                                    borderRadius: '8px',
                                    fontWeight: '600',
                                    fontSize: '13px',
                                    color: '#fff',
                                    backgroundColor: '#007bff',
                                    borderColor: '#000',
                                    transition: 'all 0.2s ease',
                                    boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                                }}
                            >
                                Abonelik Planını Değiştir
                        </CButton>
                    )}

                    {subscriptionDetails.subscriptionStatus === 'ACTIVE' && (
                        <CButton
                            color="danger"
                            className="w-100 mt-4 py-3"
                            onClick={onCancelSubscription}
                            style={{
                                borderRadius: '8px',
                                fontWeight: '600',
                                fontSize: '13px',
                                color: '#fff',
                                backgroundColor: '#dc3545',
                                borderColor: '#000',
                                transition: 'all 0.2s ease',
                                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                            }}
                        >
                            Aboneliği İptal Et
                        </CButton>
                    )}
                </CCardBody>
            </CCard>

            {/* Orders History Card */}
            {subscriptionDetails.orders && subscriptionDetails.orders.length > 0 && (
                <CCard className="border-0 shadow-sm mt-4">
                    <CCardHeader className="bg-white border-bottom py-3">
                        <h2 className="m-0 fs-4" style={{ color: "#555" }}>
                            Sipariş Geçmişi
                        </h2>
                    </CCardHeader>
                    <CCardBody className="p-4">
                        <div className="table-responsive">
                            <CTable hover align="middle" className="mb-0">
                                <CTableHead>
                                    <CTableRow>
                                        <CTableHeaderCell className="border-0 py-3">Referans Kodu</CTableHeaderCell>
                                        <CTableHeaderCell className="border-0 py-3">Başlangıç</CTableHeaderCell>
                                        <CTableHeaderCell className="border-0 py-3">Bitiş</CTableHeaderCell>
                                        <CTableHeaderCell className="border-0 py-3">Tutar</CTableHeaderCell>
                                        <CTableHeaderCell className="border-0 py-3">Durum</CTableHeaderCell>
                                    </CTableRow>
                                </CTableHead>
                                <CTableBody>
                                    {subscriptionDetails.orders.map((order) => (
                                        <CTableRow key={order.referenceCode}>
                                            <CTableDataCell className="py-3">
                                                <span className="text-secondary">{order.referenceCode}</span>
                                            </CTableDataCell>
                                            <CTableDataCell className="py-3">{formatDate(order.startPeriod)}</CTableDataCell>
                                            <CTableDataCell className="py-3">{formatDate(order.endPeriod)}</CTableDataCell>
                                            <CTableDataCell className="py-3">
                                                {formatPrice(order.price, order.currencyCode)}
                                            </CTableDataCell>
                                            <CTableDataCell className="py-3">
                                                <CBadge
                                                    color={getStatusColor(order.orderStatus)}
                                                    className="px-2 py-1"
                                                >
                                                    {order.orderStatus}
                                                </CBadge>
                                            </CTableDataCell>
                                        </CTableRow>
                                    ))}
                                </CTableBody>
                            </CTable>
                        </div>
                    </CCardBody>
                </CCard>
            )}
        </div>
    );
};

export default SubscriptionDetails;