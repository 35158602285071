import React from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import {AuthProvider} from './contexts/AuthContext';
import Header from './components/Header/Header';
import ProtectedRoute from './components/ProtectedRoute';
import DokumanAramaPage from './views/DokumanAramaView.tsx';
import './App.css';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import '@coreui/coreui/dist/css/coreui.min.css';
import './styles/scss/style.scss';
import './styles/custom_styles.css';
import ImprovedSearchView from './views/ImprovedSearchView';
import SelectedDocumentView from './views/SelectedDocumentView.tsx';
import {CFooter, CLink} from '@coreui/react';
import AssistantView from "./views/AssistantView";
import ProfileView from "./views/ProfileView";
import PaymentFailedView from "./views/PaymentFailedView";
import HomeRedirect from "./components/HomeRedirect";
import {SubscriptionTier} from "./model/enum/subscriptionTier.ts";
import PaymentSuccessView from "./views/PaymentSuccessView";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <RoutesApp />
      </Router>
    </AuthProvider>
  );
};

const RoutesApp = () => {
  
  const location = useLocation();
  const showHeader = location.pathname === "/login";

  return (
    <>
      {showHeader && <Header />}
      <div className="content">
        <Routes>
          {/* Public routes */}
          <Route path="/" element={<HomeRedirect />} />
          <Route path="/login" element={<Login />} />
          <Route path="/register" element={<Register />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
          <Route path="/payment-success" element={<PaymentSuccessView/>}/>
          <Route path="/payment-failed" element={<PaymentFailedView/>}/>

          {/* Basic subscription routes */}
          <Route path="/profile" element={<ProtectedRoute requiredTier={SubscriptionTier.BASIC}><ProfileView /></ProtectedRoute>} />
          <Route path="/profile/favorites" element={<ProtectedRoute requiredTier={SubscriptionTier.BASIC}><ProfileView /></ProtectedRoute>} />
          <Route path="/dokuman-arama-page" element={<ProtectedRoute requiredTier={SubscriptionTier.BASIC}><DokumanAramaPage /></ProtectedRoute>} />
          <Route path="/selected-document" element={<ProtectedRoute requiredTier={SubscriptionTier.BASIC}><SelectedDocumentView /></ProtectedRoute>} />

          {/* Pro subscription routes */}
          <Route path="/yapay-zeka-ile-arama" element={<ProtectedRoute requiredTier={SubscriptionTier.PRO}><ImprovedSearchView key={Date.now()}/></ProtectedRoute>} />
          <Route path="/asistan" element={<ProtectedRoute requiredTier={SubscriptionTier.PRO}><AssistantView/></ProtectedRoute>}/>

        </Routes>
      </div>
    </>
  );
};

export default App;
