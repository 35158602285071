import React, {
  useState,
  useEffect,
  useCallback,
  useRef,
  useContext,
} from "react";
import {
  CInputGroup,
  CFormInput,
  CContainer,
  CRow,
  CCol,
  CButton,
  CSpinner,
  CAlert, CFormCheck,
} from "@coreui/react";
import VerticalNavbar from "../components/VerticalNavbar";
import { marked } from "marked";
import { parseIctihatBody } from "../utils/DocumentSearchUtils";
import CIcon from "@coreui/icons-react";
import { cibCircle, cilChevronCircleLeftAlt } from "@coreui/icons";
import { AuthContext } from "../contexts/AuthContext";
import chatService from "../service/ChatService";
import firebaseClient from "../client/FirebaseClient";
import leagleClient from "../client/LeagleClient";
import Button from "../components/ui/Button/Button";
import Input from "../components/ui/Input/Input";
import "./css/ImprovedSearchView.css";
import Checkbox from '../components/ui/checkbox/Checkbox'
const ImprovedSearchView = () => {
  const { user } = useContext(AuthContext);
  const [query, setQuery] = useState("");
  const [firstQuery, setFirstQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [currentThreadId, setCurrentThreadId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [showDocumentModal, setShowDocumentModal] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [marginTop, setMarginTop] = useState("20vh");
  const [referenceDocuments, setReferenceDocuments] = useState([]);
  const [widthVal, setWidthVal] = useState("250px");
  const widthValNumber = parseInt(widthVal);
  const widthValNumberLast = widthValNumber+10;
  const [documentTypes, setDocumentTypes] = useState(new Set());

  const handleDocumentTypeChange = (event) => {
    const { value, checked } = event.target;

    setDocumentTypes((prevTypes) => {
      const newTypes = new Set(prevTypes);
      if (checked) {
        newTypes.add(value);
      } else {
        newTypes.delete(value);
      }
      return newTypes;
    });
  };

  const addReferenceDocuments = (newDocuments) => {
    if (newDocuments.length > 0) {
      setReferenceDocuments((prevDocuments) => [
        ...prevDocuments,
        ...newDocuments,
      ]);
    }
  };

  const handleImprovedSearch = useCallback(
    async (query) => {
      setIsLoading(true);
      setError(null);
      try {
        const isNewThread = firstQuery === "";
        const docTypesArray = Array.from(documentTypes);
        const response = await chatService.assistant(
            query,
            isNewThread,
            currentThreadId,
            docTypesArray
        );
        await setCurrentThreadId(response.data.thread_id);
        const newResponse = { speaker: "ai", message: response.data.response };
        addReferenceDocuments(response.data.reference_documents);
        setSearchResults((prevResults) => [...prevResults, newResponse]);
      } catch (error) {
        setError("Failed to fetch search results");
      } finally {
        setIsLoading(false);
      }
    },
    [firstQuery, currentThreadId, documentTypes]
  );

  const handleDocumentClick = useCallback(async (document) => {
    try {
      let requestBody;
      if(document.metadata.document_type === 'yargitay' || document.metadata.document_type === 'danistay'){
        requestBody = {
          document_type: document.metadata.document_type,
          params: {
            karar_no: document.metadata.karar_no,
            esas_no: document.metadata.esas_no
          }
        }
      }
      else if(document.metadata.document_type === 'mevzuat'){
        requestBody = {
          document_type: document.metadata.document_type,
          params: {
            mevzuat_no: document.metadata.mevzuat_no
          }
        }
      }
      else{
        return;
      }
      const response = await leagleClient.post(`/dokuman_arama_motoru/document/`, requestBody);
      const fullDocument = response.data;
      setSelectedDocument(fullDocument);
      setShowDocumentModal(true);
    } catch (error) {
      console.error('Error fetching document:', error);
    }
  }, []);

  const onSearchClick = useCallback(
    (query) => {
      if (!firstQuery) {
        setFirstQuery(query);
        setShowHeader(false);
      } else {
        setSearchResults((prevResults) => [
          ...prevResults,
          { speaker: "User", message: query },
        ]);
      }
      handleImprovedSearch(query);
    },
    [firstQuery, handleImprovedSearch]
  );

  const handleClick = useCallback(
    (question) => {
      setQuery(question);
      onSearchClick(question);
    },
    [onSearchClick]
  );

  useEffect(() => {
    const updateMargin = () => {
      if (!firstQuery) {
        setMarginTop(`${window.innerHeight / 2 - 250}px`);
      } else {
        setMarginTop("auto");
      }
    };
    updateMargin();
    window.addEventListener("resize", updateMargin);
    return () => window.removeEventListener("resize", updateMargin);
  }, [firstQuery]);

  const fetchPreviousThreadFromFirestore = async (thread_id) => {
    const docSnap = await firebaseClient.fetchThread(thread_id);

    if (docSnap.exists()) {
      setCurrentThreadId(thread_id);
      const threadData = docSnap.data();
      const messages = threadData.responses.map((response) => ({
        speaker: response.speaker,
        message: response.message,
      }));

      setFirstQuery(threadData.query);
      setSearchResults(messages);
      setReferenceDocuments(threadData.referenceDocuments);
    }
  };

  const ReferenceDocuments = ({ firstQuery, referenceDocuments }) => {
    if (!referenceDocuments || referenceDocuments.length === 0) {
      return (
          <div className="reference-documents-container">
            {firstQuery && <h2 style={{ fontSize: "30px" }}>{firstQuery}</h2>}
            <div
                style={{
                  width: "100%",
                  height: "1px",
                  backgroundColor: "#ccc",
                  marginTop: "20px",
                }}
            ></div>
          </div>
      );
    }

    const categorizedDocuments = referenceDocuments.reduce((acc, doc) => {
      const documentType = doc.metadata?.document_type;
      const categoryMap = {
        'yargitay': 'Yargıtay',
        'danistay': 'Danıştay',
        'mevzuat': 'Mevzuat',
        'other': 'Diğer'
      };

      const category = categoryMap[documentType] || 'Diğer';
      if (!acc[category]) acc[category] = [];
      acc[category].push(doc);
      return acc;
    }, {});

    return (
        <div className="reference-documents-container">
          {firstQuery && (
            <h2 style={{ fontSize: "24px", marginBottom: "15px", width: "100%" }}>
              {firstQuery}
            </h2>
          )}
          {Object.entries(categorizedDocuments).map(([category, docs]) => (
                <div key={category} className="category-container">
                  <h3>{category}</h3>
                {docs.map((doc, index) => (
                    <div 
                        style={{marginBottom:'5px'}}
                        key={doc.id || index}
                        className="reference-document-box"
                        onClick={() => handleDocumentClick(doc)}
                    >
                      {doc.metadata?.document_type === 'yargitay' || doc.metadata?.document_type === 'danistay' 
                        ? 'Karar Numarası: ' + doc.metadata?.karar_no
                        : doc.metadata?.document_type === 'mevzuat'
                          ? doc.metadata?.title 
                          : 'İsimsiz Doküman'}
                    </div>
                ))}
              </div>
          ))}
            
          <div
              style={{
                width: "100%",
                height: "1px",
                backgroundColor: "#ccc",
                marginTop: "20px",
              }}
          ></div>
        </div>
    );
  };

  const DocumentModal = () => {
    if (!showDocumentModal || !selectedDocument) return null;

    return (
      <div className="modal-container">
        <div
          className="modal-overlay"
          onClick={() => setShowDocumentModal(false)}
        ></div>
        <div className="modal-content">
          <button
            className="close-button"
            onClick={() => setShowDocumentModal(false)}
          >
            ×
          </button>
          <p>{parseIctihatBody(selectedDocument.content)}</p>
        </div>
      </div>
    );
  };

  const getFirstName = (fullName) => {
    return fullName.split(" ")[0];
  };

  const SearchResults = ({ searchResults, error }) => {
    if (error) {
      return <CAlert color="danger">Bir Hata Oluştu!</CAlert>;
    }

    if (searchResults.length === 0) {
      return null;
    }

    let nameUser = user.displayName || user.email || "Kullanıcı";
    nameUser = getFirstName(nameUser);
    return (
      <div>
        {searchResults.map((chat, index) => (
          <div key={index}>
            <strong>{chat.speaker === "ai" ? "Leagle AI" : nameUser}:</strong>
            <div dangerouslySetInnerHTML={{ __html: marked(chat.message) }} />
          </div>
        ))}
      </div>
    );
  };

  const SearchAndSuggest = ({ query, setQuery, onSearchClick, firstQuery }) => {
    const questions = [
      "Fikri mülkiyet ihlallerine dair güncel kararlar neler?",
      "Anlaşmalı boşanma için dava dilekçesi taslağı",
      "Tüketici hakları ihlali ile cezalandırılan kurum kararları",
      "Leagle nasıl fark yaratıyor?",
    ];
    const inputRef = useRef(null);
    const [localQuery, setLocalQuery] = useState("");

    const handleChange = (e) => {
      setLocalQuery(e.target.value);
    };

    const handleLocalSearchClick = () => {
      onSearchClick(localQuery);
      setLocalQuery("");
    };

    const handleKeyDown = (e) => {
      if (e.key === "Enter") {
        handleLocalSearchClick();
      }
    };

    return (
      <div style={{ width: "100%" }}>
        {!firstQuery && (
          <h1 style={{ textAlign: "center", paddingBottom: "8px" }}>
            Bilginin başladığı yer.
          </h1>
        )}
        <div className="d-flex ">
          <Input
            type="text"
            ref={inputRef}
            value={localQuery}
            onChange={handleChange}
            style={{ width: "100%", marginRight: "10px" }}
            placeholder={
              firstQuery ? "Takip soruları sorun..." : "Dilediğini sor..."
            }
            aria-label="Search input"
            className="h-100"
            onKeyDown={handleKeyDown}
          />
          <Button
            onClick={handleLocalSearchClick}
            disabled={isLoading}
            color="primary"
          >
            {isLoading ? <CIcon icon={cibCircle} /> : <a>Gönder</a>}
          </Button>
        </div>
        {!firstQuery && (
          <div className="questionsGrid">
            {questions.slice(0, 4).map((question, index) => (
              <div key={index} style={{ width: "100%", marginBottom: "10px" }}>
                <CButton
                  className="nav-link-yeni-konu"
                  onClick={() => handleClick(question)}
                  style={{
                    width: "100%",
                    whiteSpace: "normal",
                    lineHeight: "1.5",
                    fontSize: "15px",
                  }}
                >
                  {question}
                </CButton>
              </div>
            ))}
          </div>
        )}
        {!firstQuery && (
          <p style={{ textAlign: "center" }}>Leagle can make mistakes.</p>
        )}
      </div>
    );
  };

  return (
    <div className="full-height">
      <div className="h-100">
        <div>
          <VerticalNavbar
            widthVal={widthVal}
            setWidthVal={setWidthVal}
            fetchPreviousThread={fetchPreviousThreadFromFirestore}
          />
        </div>
        <div
          style={{
            padding: `0 35px 0 ${widthValNumberLast}px`,
            transition: "padding-left 0.4s ease",
            overflow:'hidden'
          }}
        >
          <CContainer className="reference-documents-container">
            {/*
            {!firstQuery &&
                <div>
                  <h3>Aramaya Eklenecek Doküman Türleri:</h3>
                  <Checkbox inline id="inlineCheckbox1" value="yargitay" label="Yargıtay" onChange={handleDocumentTypeChange} checked={documentTypes.has('yargitay')}/>
                  <Checkbox inline id="inlineCheckbox2" value="danistay" label="Danıştay" onChange={handleDocumentTypeChange} checked={documentTypes.has('danistay')}/>
                  <Checkbox inline id="inlineCheckbox3" value="mevzuat" label="Mevzuat" onChange={handleDocumentTypeChange} checked={documentTypes.has('mevzuat')}/>
                </div>
            }
            */}
            
            <ReferenceDocuments
              firstQuery={firstQuery}
              referenceDocuments={referenceDocuments}
              handleDocumentClick={(doc) => showDocumentModal(doc)}
            />
          </CContainer>
          <CContainer>
            <SearchResults searchResults={searchResults} error={error} />
            {isLoading ? (
              <CContainer
                style={{
                  justifyContent: "center",
                  alignItems: "center",
                  display: "flex",
                  marginBottom: "2vh",
                }}
              >
                <CSpinner></CSpinner>
              </CContainer>
            ) : null}
          </CContainer>
          <div
            style={{
              marginTop: marginTop,
              width: "100%",
              transition: "margin-top 0.5s ease-out",
              minWidth:'250px',
              overflow:'hidden'
            }}
          >
            <SearchAndSuggest
              query={query}
              setQuery={setQuery}
              onSearchClick={onSearchClick}
              firstQuery={firstQuery}
            />
          </div>
        </div>
        {showDocumentModal && <DocumentModal />}
      </div>
    </div>
  );
};

export default ImprovedSearchView;
