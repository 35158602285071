import React, { useRef, useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import {
  CButton,
  CCard,
  CCardBody,
  CCardHeader,
  CCol,
  CContainer,
  CForm,
  CRow,
  CSpinner,
  CTooltip,
} from "@coreui/react";
import { cilArrowBottom, cilHeart } from "@coreui/icons";
import CIcon from "@coreui/icons-react";
import { doc, getDoc, setDoc } from "firebase/firestore";
import { Toast } from "primereact/toast";
import Button from "../components/ui/Button/Button";
import Button2 from "../components/ui/Button2/Button2";
import Input from '../components/ui/Input/Input';
import { auth, db } from "../firebaseConfig";
import chatService from "../service/ChatService";
import {
  formatDate,
  getScoreColor,
  getScoreWidth,
  parseIctihatBody,
} from "../utils/DocumentSearchUtils";
import { BaseResponse, DocumentType, yargitayResponse} from '../types/documentTypes';
import searchService from "../service/SearchService";

interface FullDocument {
  content: string;
  document_type: string;
  date: string;
  daire: string;
  esas_no: string;
  karar_no: string;
}

interface LocationState {
  document: BaseResponse;
  query: string;
  results: BaseResponse[];
  activeButton: DocumentType;
}

interface ChatMessage {
  user: string;
  response: string;
}

const SelectedDocumentView: React.FC = () => {
  const [isHovered, setIsHovered] = useState<boolean>(false);
  const toast = useRef<Toast>(null);
  const sessionID = uuidv4();
  const location = useLocation();
  const locationState = location.state as LocationState;
  const [selectedDocument, setSelectedDocument] = useState<BaseResponse | null>(locationState?.document);
  const [fullDocument, setFullDocument] = useState<FullDocument | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const query = locationState?.query;
  const activeButton = locationState?.activeButton;
  const results = locationState?.results;
  const navigate = useNavigate();

  const [userInput, setUserInput] = useState<string>("");
  const [conversation, setConversation] = useState<ChatMessage[]>([]);
  const [summary, setSummary] = useState<string>("");
  const [isChatMinimized, setIsChatMinimized] = useState<boolean>(false);
  const [isChatLoading, setIsChatLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchFullDocument = async () => {
        try {
            const params = {
                esas_no: (selectedDocument as yargitayResponse).metadata.esas_no,
                karar_no: (selectedDocument as yargitayResponse).metadata.karar_no,
            }
            const fullDocument = await searchService.searchDocument(selectedDocument.metadata.document_type, params);
            if (fullDocument) {
                setFullDocument(fullDocument);
            }
        } catch (error) {
            console.error("Error fetching full document:", error);
            showToast("error", "Doküman detayları yüklenirken bir hata oluştu");
        } finally {
            setIsLoading(false);
        }
    };
    fetchFullDocument();
  }, []);

  const renderDocumentHeader = (): JSX.Element => {
    if (!selectedDocument) return <></>;

    switch (selectedDocument.metadata.document_type) {
      case "yargitay":
      case "danistay":
        return (
          <>
            <h3 style={{ margin: "0", fontWeight: "600" }}>
              {fullDocument?.daire} - Karar No: {fullDocument?.karar_no} - Esas No: {fullDocument?.esas_no}
            </h3>
          </>
        );
      default:
        return <div>Bilinmeyen doküman tipi</div>;
    }
  };

  const showToast = (severity: "success" | "info" | "error", detail: string) => {
    toast.current?.show({
      severity,
      summary: severity === "error" ? "Hata!" : "Başarılı!",
      detail,
      life: 3000,
    });
  };

  const goBack = () => {
    navigate("/dokuman-arama-page", {
      state: { query, results, activeButton },
    });
  };

  const handleChat = async () => {
    setIsChatLoading(true);
    try {
      const response = await chatService.chatWithDocument(
        selectedDocument,
        userInput,
        sessionID
      );
      setConversation([...conversation, { user: userInput, response }]);
      setUserInput("");
    } catch (error) {
      console.error("Chat error:", error);
      showToast("error", "Sohbet sırasında bir hata oluştu");
    } finally {
      setIsChatLoading(false);
    }
  };

  const handleSummarize = async () => {
    setIsChatLoading(true);
    try {
      const response = await chatService.summarizeDocument(selectedDocument, sessionID);
      setSummary(response);
    } catch (error) {
      console.error("Summarize error:", error);
      showToast("error", "Özetleme sırasında bir hata oluştu");
    } finally {
      setIsChatLoading(false);
    }
  };

  const addFavoritesToFirestore = async () => {
    const user = auth.currentUser;
    if (!user) {
      showToast("error", "Oturum açmanız gerekiyor");
      return;
    }

    const favoritesRef = doc(db, `users/${user.uid}/favorites`, selectedDocument.metadata.id);

    try {
      const docSnap = await getDoc(favoritesRef);
      if (docSnap.exists()) {
        showToast("info", "Doküman zaten favorilerinde");
      } else {
        await setDoc(favoritesRef, selectedDocument);
        showToast("success", "Doküman favorilere eklendi");
      }
    } catch (error) {
      console.error("Favorites error:", error);
      showToast("error", "Doküman favorilere eklenirken bir hata oluştu");
    }
  };

  if (!fullDocument || isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center" style={{ height: "100vh" }}>
        <CSpinner />
      </div>
    );
  }

  return (
    <CContainer style={{ marginTop: "5vh" }}>
      <Toast ref={toast} />
      <CRow className="justify-content-center">
        <CCol style={{ marginBottom: "60px" }}>
          <CCard>
            <CCardHeader
              style={{
                padding: "20px",
                boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
              }}
            >
              <CTooltip content="Favorilerine Ekle" placement="top">
                <button
                  onClick={addFavoritesToFirestore}
                  style={{ marginBottom: "10px", color: "#6B1A28" }}
                  onMouseEnter={() => setIsHovered(true)}
                  onMouseLeave={() => setIsHovered(false)}
                  aria-label="Favorite"
                >
                  <CIcon
                    icon={cilHeart}
                    size="xl"
                    style={{ opacity: isHovered ? "50%" : "100%" }}
                  />
                </button>
              </CTooltip>

              <div className="d-flex justify-content-between align-items-center mb-2">
                <div>
                  {renderDocumentHeader()}
                </div>
                <div style={{ textAlign: "right" }}>
                  <div
                    style={{
                      fontWeight: "600",
                      color: getScoreColor(selectedDocument.similarity_score),
                    }}
                  >
                    Skor: {(100 * selectedDocument.similarity_score).toFixed(0)}
                  </div>
                  <div style={{ fontSize: "0.85em", color: "#888" }}>
                    {formatDate(selectedDocument.metadata.date)}
                  </div>
                </div>
              </div>
              <div
                style={{
                  height: "10px",
                  width: "100%",
                  backgroundColor: "#f0f0f0",
                  borderRadius: "5px",
                }}
              >
                <div
                  style={{
                    height: "10px",
                    width: getScoreWidth(selectedDocument.similarity_score),
                    backgroundColor: getScoreColor(selectedDocument.similarity_score),
                    borderRadius: "5px",
                  }}
                />
              </div>
            </CCardHeader>

            <Button
              style={{
                position: "fixed",
                height: "30px",
                top: "80px",
                left: "20px",
                zIndex: 1000,
                padding: "5px 10px",
                fontSize: "12px",
              }}
              onClick={goBack}
            >
              Geri
            </Button>

            <CCardBody>
              <div className="ictihat-content">
                {parseIctihatBody(fullDocument?.content)}
              </div>
            </CCardBody>
          </CCard>
        </CCol>
      </CRow>

      {/* Chat Container */}
      <CContainer
        className={`document-chat-container ${isChatMinimized ? "minimized" : ""}`}
        style={{
          position: "fixed",
          height: isChatMinimized ? "50px" : "400px",
          width: "500px",
          transition: "height 0.3s",
          overflow: "hidden",
        }}
      >
        <CCard className="document-chat-area" style={{ height: "100%" }}>
          <CCardHeader>Leagle AI</CCardHeader>
          <CCardBody
            className={`document-chat-content ${isChatMinimized ? "hidden-content" : ""}`}
            style={{ maxHeight: "100%", overflowY: "auto" }}
          >
            {summary && (
              <div className="summary-section">
                <h4 style={{ fontWeight: "700" }}>Özet</h4>
                <div style={{ whiteSpace: "pre-line", fontSize: "0.85em" }}>
                  {summary}
                </div>
                <hr />
              </div>
            )}
            
            <div>
              {conversation.map((chat, index) => (
                <div key={index} style={{ fontSize: "0.85em" }}>
                  <strong>Kullanıcı:</strong> {chat.user}
                  <br />
                  <strong>Yanıt:</strong> {chat.response}
                  <hr />
                </div>
              ))}
            </div>

            {isChatLoading && (
              <div className="d-flex justify-content-center align-items-center p-3">
                <CSpinner />
              </div>
            )}
          </CCardBody>

          {!isChatMinimized && (
            <div className="d-flex justify-content-center w-100">
              <CForm
                className="d-flex justify-content-center align-items-center"
                style={{
                  padding: "10px",
                  width: "100%",
                  maxWidth: "450px",
                }}
              >
                <Input
                  type="text"
                  value={userInput}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setUserInput(e.target.value)}
                  placeholder="Dokümana soru sorun..."
                  style={{
                    flexGrow: 1,
                    marginRight: "10px",
                  }}
                />
                <div className="d-flex">
                  <Button
                    onClick={handleChat}
                    style={{
                      padding: "10px",
                      marginRight: "10px",
                      fontSize: "12px",
                    }}
                    disabled={isChatLoading}
                  >
                    Sor
                  </Button>
                  <CButton
                    className="nav-link-profile"
                    onClick={handleSummarize}
                    style={{
                      padding: "10px",
                      marginBottom: "0",
                      fontSize: "12px",
                    }}
                    disabled={isChatLoading}
                  >
                    Özetle
                  </CButton>
                </div>
              </CForm>
            </div>
          )}

          <Button2
            onClick={() => setIsChatMinimized(!isChatMinimized)}
            color="primary"
            style={{
              position: "absolute",
              top: "2.5px",
              right: "2.5px",
              padding: "2px",
              fontSize: "12px",
              width: "25px",
              height: "25px",
            }}
          >
            <CIcon
              style={{
                transform: isChatMinimized ? "rotate(180deg)" : "rotate(0deg)",
              }}
              icon={cilArrowBottom}
            />
          </Button2>
        </CCard>
      </CContainer>
    </CContainer>
  );
};

export default SelectedDocumentView;