import React, { useContext } from 'react';
import {Navigate, useLocation} from 'react-router-dom';
import { AuthContext } from '../contexts/AuthContext';
import Spinner from "./Spinner";
import {SubscriptionTier} from "../model/enum/subscriptionTier.ts";

const TIER_HIERARCHY = {
  [SubscriptionTier.EXEMPT]: 3,
  [SubscriptionTier.PRO]: 2,
  [SubscriptionTier.BASIC]: 1
};

const ProtectedRoute = ({ children, requiredTier = SubscriptionTier.BASIC }) => {
  const { user, loading, subscriptionTier } = useContext(AuthContext);
  const location = useLocation();

  if (loading) {
    return <Spinner />;
  }

  if (!user) {
    return <Navigate to="/login" />;
  }

  const userTierLevel = TIER_HIERARCHY[subscriptionTier] || 0;
  const requiredTierLevel = TIER_HIERARCHY[requiredTier] || 0;

  if (location.pathname === '/asistan' && !(user.email === 'bakarsu16@ku.edu.tr' || user.email === 'hhaligur19@ku.edu.tr' || user.email === 'hititoguzkagan@gmail.com' || user.email.includes('@aksan.av.tr'))) {
    return <Navigate to="/" />;
  }

  if (userTierLevel >= requiredTierLevel) {
    return children;
  }

  return <Navigate to="/" />;
};

export default ProtectedRoute;