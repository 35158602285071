import React, { useState, useEffect } from "react";
import {
  CContainer,
  CRow,
  CCol,
  CCard,
  CCardHeader,
} from "@coreui/react";
import { useNavigate, useLocation } from "react-router-dom";
import { PropagateLoader } from "react-spinners";
import SelectedDocumentView from '../views/SelectedDocumentView.tsx';
import DokumanAramaSearchInput from "../components/DokumanAramaSearchInput";
import IctihatFilterPanel from "../components/IctihatFilterPanel";
import MevzuatFilterPanel from "../components/MevzuatFilterPanel";
import VerticalNavbar from "../components/VerticalNavbar";
import searchService from "../service/SearchService";
import {
  formatDate,
  getScoreColor,
  getScoreWidth,
} from "../utils/DocumentSearchUtils";
import "./css/DokumanAramaView.css";

// Types
interface DateState {
  day: string;
  month: string;
  year: string;
}

interface DaireSelection {
  name: string;
  code?: string;
}

interface MevzuatCategory {
  name: string;
  code: string;
}

interface BaseResponse {
    content: string;
    metadata: {
        date: string;
        document_type: string;
    };
    similarity_score: number;
};

interface LocationState {
  query?: string;
  results?: BaseResponse[];
  activeButton?: string;
  document?: BaseResponse;
}

interface mevzuatResponse extends BaseResponse {
  content: string;
  metadata: {
    date: string;
    document_type: string;
    id: string;
    kabulTarih: string;
    link: string;
    mevzuatTertip: number;
    mevzuat_no: number;
    mevzuat_type: string;
    resmiGazeteSayisi: number;
    resmiGazeteTarihi: string;
    start_index: number;
    title: string;
  };
  similarity_score: number;
}

interface yargitayResponse extends BaseResponse {
  content: string;
  metadata: {
    daire: string;
    date: string;
    document_type: string;
    esas_no: string;
    id: string;
    karar_no: string;
    start_index: number;
  };
  similarity_score: number;
}

interface danistayResponse extends BaseResponse {
  content: string;
  metadata: {
    daire: string;
    date: string;
    document_type: string;
    esas_no: string;
    id: string;
    karar_no: string;
    start_index: number;
  };
  similarity_score: number;
}

interface spkResponse extends BaseResponse {
  content: string;
  metadata: {
    bulten_no: string;
    date: string;
    document_type: string;
    id: string;
    source_url: string;
    start_index: number;
    subtype: string;
  };
  similarity_score: number;
}

interface rekabetKurumuResponse extends BaseResponse {
  content: string;
  metadata: {
    date: string;
    document_type: string;
    id: string;
    karar_sayisi: string;
    karar_tarihi: string;
    karar_turu: string;
    source_url: string;
    start_index: number;
    title: string;
  };
  similarity_score: number;
}

type DocumentType = "yargitay" | "danistay" | "mevzuat" | "spk" | "rekabet-kurumu";

const DOCUMENT_TYPE_DISPLAY: Record<DocumentType, string> = {
  "yargitay": "Yargıtay",
  "danistay": "Danıştay",
  "mevzuat": "Mevzuat",
  "spk": "SPK",
  "rekabet-kurumu": "Rekabet Kurumu"
};

const DokumanAramaView: React.FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const locationState = location.state as LocationState;

  // State management
  const [query, setQuery] = useState<string>("");
  const [staticResults, setStaticResults] = useState<BaseResponse[]>([]);
  const [results, setResults] = useState<BaseResponse[]>([]);
  const [selectedDocument, setSelectedDocument] = useState<BaseResponse | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [activeButton, setActiveButton] = useState<DocumentType>("yargitay");
  const [widthVal, setWidthVal] = useState<string>("250px");

  // Date state
  const [startDate, setStartDate] = useState<DateState>({ day: "", month: "", year: "" });
  const [endDate, setEndDate] = useState<DateState>({ day: "", month: "", year: "" });
  
  // Selection state
  const [selectedHukukDairesi, setSelectedHukukDairesi] = useState<DaireSelection[]>([]);
  const [selectedCezaDairesi, setSelectedCezaDairesi] = useState<DaireSelection[]>([]);
  const [selectedMevzuatCategory, setSelectedMevzuatCategory] = useState<MevzuatCategory[]>([]);

  const widthValNumber = parseInt(widthVal);
  const widthValNumberLast = widthValNumber + 10;

  useEffect(() => {
    if (locationState) {
      setQuery(locationState.query || "");
      if (locationState.results) {
        setResults(locationState.results);
        setStaticResults(locationState.results);
      }
      if (locationState.activeButton as DocumentType) {
        setActiveButton(locationState.activeButton as DocumentType);
      }
    }
  }, [locationState]);

  const displayDetailedCard = (document: BaseResponse): void => {
    if (document.metadata?.document_type === 'mevzuat') {
      window.open((document as mevzuatResponse).metadata.link, '_blank');
    } else if (document.metadata?.document_type === 'spk') {
      window.open((document as spkResponse).metadata.source_url, '_blank'); 
    } else if (document.metadata?.document_type === 'rekabet_kurumu') {
      window.open((document as rekabetKurumuResponse).metadata.source_url, '_blank'); 
    } else {
      navigate("/selected-document", {
        state: {
          document,
          query,
          results: staticResults,
          activeButton,
        },
      });
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent): void => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  const handleSearch = async (): Promise<void> => {
    setIsLoading(true);
    try {
      const data = await searchService.searchDocuments(query, activeButton);
      setResults(data);
      setStaticResults(data);
    } catch (error) {
      console.error("Search failed:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const sortByRelevancy = (): void => {
    setResults([...staticResults]);
  };

  const sortByDate = (): void => {
    const sortedArray = [...results].sort((a, b) => {
      return new Date(b.metadata?.date?.split(".").reverse().join("-") || "").getTime() - new Date(a.metadata?.date?.split(".").reverse().join("-") || "").getTime();
    });
    setResults(sortedArray);
  };

  const filterByDate = (items: BaseResponse[]): BaseResponse[] => {
    if (startDate.day && startDate.month && startDate.year && 
        endDate.day && endDate.month && endDate.year) {
      const beginDate = new Date(
        parseInt(startDate.year),
        parseInt(startDate.month) - 1,
        parseInt(startDate.day)
      );
      const endDateObj = new Date(
        parseInt(endDate.year),
        parseInt(endDate.month) - 1,
        parseInt(endDate.day)
      );
      return items.filter((item) => {
        const itemDate = new Date(item.metadata?.date?.split(".").reverse().join("-") || "");
        return itemDate >= beginDate && itemDate <= endDateObj;
      });
    }
    return items;
  };

  const filterByMevzuatCategory = (items: BaseResponse[]): BaseResponse[] => {
    if (selectedMevzuatCategory.length === 0) return items;
    return items.filter((item) =>
      selectedMevzuatCategory.some(
        (category) => (item as mevzuatResponse).metadata?.mevzuat_type === category.code
      )
    );
  };

  const filterByDaire = (items: BaseResponse[]): BaseResponse[] => {
    const combinedDairesi = [...selectedHukukDairesi, ...selectedCezaDairesi];
    if (combinedDairesi.length === 0) return items;
    return items.filter((item) => {
      if (activeButton === "yargitay") {
        return combinedDairesi.some((daire) => (item as yargitayResponse).metadata?.daire === daire.name);
      } else if (activeButton === "danistay") {
        return combinedDairesi.some((daire) => (item as danistayResponse).metadata?.daire === daire.name);
      }
      return false;
    });
  };

  const filterMevzuatResults = (): void => {
    let filteredArray = [...staticResults];
    filteredArray = filterByMevzuatCategory(filteredArray);
    filteredArray = filterByDate(filteredArray);
    setResults(filteredArray);
  };

  const filterIctihatResults = (): void => {
    let filteredArray = [...staticResults];
    filteredArray = filterByDaire(filteredArray);
    filteredArray = filterByDate(filteredArray);
    setResults(filteredArray);
  };

  const changeDocumentTab = (documentType: DocumentType): void => {
    setActiveButton(documentType);
    setResults([]);
    setStaticResults([]);
    setQuery("");
  };

  const renderDocumentContent = (result: any, type: DocumentType): JSX.Element => {

    const mevzuatOptions = [
        { name: "Kanunlar", code: "kanun" },
        { name: "C. Kararnameleri", code: "cumhur_kararname" },
        { name: "C. ve Bakanlar Kurulu Yönetmelikleri", code: "cumhur_yonetmelik" },
        { name: "C. Kararları", code: "cumhur_karar" },
        { name: "C. Genelgeleri", code: "cumhur_genelge" },
        { name: "KHK", code: "khk" },
        { name: "Tüzükler", code: "tuzuk" },
        {
          name: "Kurum, Kuruluş ve Üniversite Yönetmelikleri",
          code: "kurum_yonetmelik",
        },
        { name: "Tebliğler", code: "teblig" },
      ];

    switch (type) {
      case "mevzuat":
        return (
          <>
            <h3 style={{ margin: "0", fontSize: "14px", fontWeight: "600" }}>
              {result.metadata?.title}
            </h3>
            <h4 style={{ margin: "0", color: "#555" }}>
              {mevzuatOptions.find(opt => opt.code === result.metadata?.mevzuat_type)?.name || result.metadata?.mevzuat_type}, Mevzuat No: {result.metadata?.mevzuat_no}
            </h4>
            <h4 style={{ margin: "0", color: "#555", marginTop: "10px", fontStyle: "italic"}}>
              {result.content}
            </h4>
          </>
        );

      case "spk":
        return (
          <>
            <h3 style={{ margin: "0", fontWeight: "600" }}>
              Bülten No: {result.metadata?.bulten_no}
            </h3>
            <h4 style={{ margin: "0", color: "#555", marginTop: "10px", fontStyle: "italic" }}>
              {result.content}
            </h4>
          </>
        );

      case "rekabet-kurumu":
        return (
          <>
            <h3 style={{ margin: "0", fontWeight: "600" }}>
              {result.metadata?.title}
            </h3>
            <h4 style={{ margin: "0", color: "#555" }}>
              Karar Sayısı: {result.metadata?.karar_sayisi}, Karar Türü: {result.metadata?.karar_turu}
            </h4>
            <h4 style={{ margin: "0", color: "#555", marginTop: "10px", fontStyle: "italic" }}>
              {result.content}
            </h4>
          </>
        );

      case "yargitay":
      case "danistay":
      default:
        return (
          <>
            <h3 style={{ margin: "0", fontWeight: "600" }}>
              Karar No: {result.metadata?.karar_no}, Esas No: {result.metadata?.esas_no}
            </h3>
            <h4 style={{ margin: "0", color: "#555" }}>
              {result.metadata?.daire}
            </h4>
            <h4 style={{ margin: "0", color: "#555", marginTop: "10px", fontStyle: "italic" }}>
              {result.content}
            </h4>
          </>
        );
    }
  };

  const renderDateInfo = (result: any, type: DocumentType): string => {    
    return formatDate(result.metadata?.date || result.date || "");
  };

  const renderSearchResults = (): JSX.Element => (
    <CContainer className="pb-5">
      <CRow>
        <CCard style={{ padding: "2rem" }}>
          <CCol>
            {activeButton === "mevzuat" ? (
              <MevzuatFilterPanel
                startDateDay={startDate.day}
                setStartDateDay={(day: string) => setStartDate(prev => ({ ...prev, day }))}
                startDateMonth={startDate.month}
                setStartDateMonth={(month: string) => setStartDate(prev => ({ ...prev, month }))}
                startDateYear={startDate.year}
                setStartDateYear={(year: string) => setStartDate(prev => ({ ...prev, year }))}
                endDateDay={endDate.day}
                setEndDateDay={(day: string) => setEndDate(prev => ({ ...prev, day }))}
                endDateMonth={endDate.month}
                setEndDateMonth={(month: string) => setEndDate(prev => ({ ...prev, month }))}
                endDateYear={endDate.year}
                setEndDateYear={(year: string) => setEndDate(prev => ({ ...prev, year }))}
                selectedMevzuatCategory={selectedMevzuatCategory}
                setSelectedMevzuatCategory={setSelectedMevzuatCategory}
                filterResults={filterMevzuatResults}
                sortByDate={sortByDate}
                sortByRelevancy={sortByRelevancy}
              />
            ) : (
              <IctihatFilterPanel
                startDateDay={startDate.day}
                setStartDateDay={(day: string) => setStartDate(prev => ({ ...prev, day }))}
                startDateMonth={startDate.month}
                setStartDateMonth={(month: string) => setStartDate(prev => ({ ...prev, month }))}
                startDateYear={startDate.year}
                setStartDateYear={(year: string) => setStartDate(prev => ({ ...prev, year }))}
                endDateDay={endDate.day}
                setEndDateDay={(day: string) => setEndDate(prev => ({ ...prev, day }))}
                endDateMonth={endDate.month}
                setEndDateMonth={(month: string) => setEndDate(prev => ({ ...prev, month }))}
                endDateYear={endDate.year}
                setEndDateYear={(year: string) => setEndDate(prev => ({ ...prev, year }))}
                selectedHukukDairesi={selectedHukukDairesi}
                setSelectedHukukDairesi={setSelectedHukukDairesi}
                selectedCezaDairesi={selectedCezaDairesi}
                setSelectedCezaDairesi={setSelectedCezaDairesi}
                filterResults={filterIctihatResults}
                sortByDate={sortByDate}
                sortByRelevancy={sortByRelevancy}
              />
            )}
          </CCol>
        </CCard>
      </CRow>
    </CContainer>
  );

  const fetchPreviousThread = (): void => {
  };

  return (
    <div className="full-height">
      <div className="h-100">
        <div>
          <VerticalNavbar
            widthVal={widthVal}
            setWidthVal={setWidthVal}
            fetchPreviousThread={fetchPreviousThread}
          />
        </div>
        <div
          style={{
            padding: `0 35px 0 ${widthValNumberLast}px`,
            transition: "padding-left 0.4s ease",
            overflow: 'hidden'
          }}
        >
          <CContainer
            style={{ gap: "20px" }}
            className="d-flex flex-md-row flex-column"
          >
            <div className="d-flex flex-column w-100">
              <div
                className="d-flex justify-content-center align-items-center"
                style={{ marginBottom: "8px", gap: "8px" }}
              >
                <div className="mydict">
                  <div>
                    {(Object.keys(DOCUMENT_TYPE_DISPLAY) as DocumentType[]).map((type) => (
                      <label key={type}>
                        <input
                          type="radio"
                          name="documentType"
                          value={type}
                          checked={activeButton === type}
                          onChange={() => changeDocumentTab(type)}
                        />
                        <span
                          style={{
                            fontSize: "14px",
                            backgroundColor: activeButton === type ? "#6B1A28" : "",
                            color: activeButton === type ? "#EFE8E1" : "",
                          }}
                        >
                          {DOCUMENT_TYPE_DISPLAY[type]}
                        </span>
                      </label>
                    ))}
                  </div>
                </div>
              </div>

              <CRow className="justify-content-center">
                <DokumanAramaSearchInput
                  query={query}
                  setQuery={setQuery}
                  handleSearch={handleSearch}
                  handleKeyDown={handleKeyDown}
                  isLoading={isLoading}
                />
              </CRow>

              <div className="w-100 mt-4">
                {isLoading ? (
                  <div className="w-100 mt-5 d-flex justify-content-center align-items-center">
                    <PropagateLoader />
                  </div>
                ) : (
                  results.map((result, index) => (
                    <CCard
                      onClick={() => displayDetailedCard(result)}
                      className="mb-3"
                      style={{ cursor: "pointer" }}
                      key={index}
                    >
                      <CCardHeader
                        style={{
                          padding: "20px",
                          boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px",                            
                          }}
                        >
                          <div style={{ marginRight: "20px" }}>
                            {renderDocumentContent(result, activeButton)}
                          </div>
                          <div style={{ textAlign: "right" }}>
                            <div
                              style={{
                                fontWeight: "600",
                                color: getScoreColor(result.similarity_score),
                              }}
                            >
                              Skor: {(100 * (result.similarity_score)).toFixed(0)}
                            </div>
                            <div style={{ fontSize: "0.85em", color: "#888" }}>
                              {renderDateInfo(result, activeButton)}
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            height: "10px",
                            width: "100%",
                            backgroundColor: "#f0f0f0",
                            borderRadius: "5px",
                          }}
                        >
                          <div
                            style={{
                              height: "10px",
                              width: getScoreWidth(result.similarity_score),
                              backgroundColor: getScoreColor(result.similarity_score),
                              borderRadius: "5px",
                            }}
                          />
                        </div>
                      </CCardHeader>
                    </CCard>
                  ))
                )}
              </div>
            </div>
            <div className="MinWidth300">
              {selectedDocument ? (
                <SelectedDocumentView />
              ) : (
                renderSearchResults()
              )}
            </div>
          </CContainer>
        </div>
      </div>
    </div>
  );
};

export default DokumanAramaView; 