import React, {useContext, useEffect, useRef, useState} from "react";
import {CContainer, CRow, CCol, CCard, CCardHeader, CCardBody, CForm, CFormLabel} from "@coreui/react";
import default_profile_image from "../assets/images/default_profile_image.png";
import Input from "./ui/Input/Input";
import Button2 from "./ui/Button2/Button2";
import {doc, getDoc, setDoc} from "firebase/firestore";
import {auth, db} from "../firebaseConfig";
import { Toast } from "primereact/toast";
import {PropagateLoader} from "react-spinners";
import subscriptionService from "../service/SubscriptionService.ts";
import {SubscriptionDetailsResponse} from "../model/response/subscriptionDetailsResponse.ts";
import {UserProfileData} from "../model/feModal/userProfileData";
import SubscriptionDetails from "./SubscriptionDetails.tsx";
import UpgradeSubscriptionPopup from "./UpgradeSubscriptionPopup.tsx";
import UserProfileDataModal from "./UserProfileDataModal.tsx";
import {AuthContext} from "../contexts/AuthContext";
import { SubscriptionTier } from "../model/enum/subscriptionTier.ts";



const Profile = () => {
    const { logout } = useContext(AuthContext);
    const [subscriptionDetails, setSubscriptionDetails] = useState<SubscriptionDetailsResponse>(null);
    const [profileData, setProfileData] = useState<UserProfileData>({
        name: "",
        email: auth.currentUser.email,
        phoneNumber: "",
        age: "",
        occupation: "",
        organization: "",
    });
    const [isSubscriptionDetailsLoading, setIsSubscriptionDetailsLoading] = useState(true);
    const [isProfileDataLoading, setIsProfileDataLoading] = useState(true);
    const toast = useRef(null);
    const [user, setUser] = useState(null);
    const [isUpgradeSubscriptionPopupVisible, setIsUpgradeSubscriptionPopupVisible] = useState(false);
    

    const showNegativeToast = (message) => {
        if (toast.current) {
            toast.current.show({
                severity: "error",
                summary: "Hatalı!",
                detail: message,
                life: 3000,
            });
        } else {
            console.error("Toast ref is null");
        }
    };

    const showPositiveToast = (message) => {
        if (toast.current) {
            toast.current.show({
                severity: "success",
                summary: "Başarılı!",
                detail: message,
                life: 3000,
            });
        } else {
            console.error("Toast ref is null");
        }
    };

    const fetchSubscriptionDetails = async () => {
        try {
            const response = await subscriptionService.getSubscriptionDetails();
            setSubscriptionDetails(response);
        } catch (error) {
            showNegativeToast("Abonelik bilgileri alınamadı!");
        } finally {
            setIsSubscriptionDetailsLoading(false);
        }
    };

    const handleCancelSubscription = async () => {
        try {
            const response = await subscriptionService.cancelSubscription();
            if (response['status'] === 'success'){
                showPositiveToast("Aboneliğiniz başarıyla iptal edildi!");
                logout();
            }
            await fetchSubscriptionDetails();
        } catch (error) {
            showNegativeToast("Abonelik iptali sırasında bir hata oluştu!");
        }
    };

    const handleUpgradeSubscription = async () => {
        try {
            const response = await subscriptionService.upgradeSubscription(sessionStorage.getItem('subscriptionTier') == 'BASIC' ? 'PRO' : 'BASIC');
            if (response['status'] === 'success'){
                showPositiveToast("Aboneliğiniz başarıyla güncellendi!");
                logout();
            }
            await fetchSubscriptionDetails();
        } catch (error) {
            showNegativeToast("Plan değişikliği sırasında bir hata oluştu!");
        }
    };

    const fetchUserProfileData = async () => {
        const currentUser = auth.currentUser;
        if (currentUser) {
            setUser(currentUser);
            const userDoc = await getDoc(doc(db, "users", currentUser.uid));
            if (userDoc.exists()) {
                const userData = userDoc.data();
                setProfileData({
                    name: userData.name || "",
                    email: auth.currentUser.email,
                    phoneNumber: userData.phoneNumber || "",
                    age: userData.age || "",
                    occupation: userData.occupation || "",
                    organization: userData.organization || "",
                });
            }
        }
        setIsProfileDataLoading(false);
    };

    function displayPropagateLoader() {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    width: "100%",
                }}
            >
                <PropagateLoader />
            </div>
        );
    }

    useEffect(() => {
        fetchUserProfileData();
        fetchSubscriptionDetails();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setProfileData((prevData) => ({ ...prevData, [name]: value }));
    };

    const handleUpdateProfileData = async (e: React.FormEvent<HTMLFormElement>)=> {
        e.preventDefault();
        try {
            const userDocRef = doc(db, "users", user.uid);
            const dataToUpdate = toPlainObject(profileData);
            await setDoc(userDocRef, dataToUpdate, { merge: true });
            showPositiveToast("Profiliniz başarıyla güncellendi!");
        } catch (error: unknown) {
            showNegativeToast("Profili güncellerken bir hata oluştu!");
        }
    };

    const toPlainObject = (data: any) => ({ ...data });

    return (
        <div className="min-h-screen bg-gray-50 py-8">
            {(isProfileDataLoading || isSubscriptionDetailsLoading) ? (
                displayPropagateLoader()
            ) : (
                <>
                    <CContainer className="mx-auto px-4">
                        <div className="max-w-3xl mx-auto space-y-6">
                            {/* Profile Card */}
                            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                                <div className="p-6 sm:p-8">
                                    <UserProfileDataModal
                                        profileData={profileData}
                                        user={user}
                                        handleChange={handleChange}
                                        handleUpdateProfileData={handleUpdateProfileData}
                                    />
                                </div>
                            </div>

                            {/* Subscription Card */}
                            <div className="bg-white rounded-lg shadow-sm overflow-hidden">
                                <div className="p-6 sm:p-8">
                                    <SubscriptionDetails
                                        subscriptionDetails={subscriptionDetails}
                                        onCancelSubscription={handleCancelSubscription}
                                        setIsUpgradeSubscriptionPopupVisible={setIsUpgradeSubscriptionPopupVisible}
                                    />
                                </div>
                            </div>
                        </div>

                        <Toast ref={toast} position="bottom-right" />
                    </CContainer>

                    <UpgradeSubscriptionPopup
                        newPricingPlan={sessionStorage.getItem('subscriptionTier') === SubscriptionTier.BASIC ? SubscriptionTier.PRO : SubscriptionTier.BASIC}
                        visible={isUpgradeSubscriptionPopupVisible}
                        closePopup={() => setIsUpgradeSubscriptionPopupVisible(false)}
                        confirmUpgrade={handleUpgradeSubscription}
                    />
                </>
            )}
        </div>
    );
};


export default Profile;
