export enum SubscriptionTier{
    BASIC = "BASIC",
    PRO = "PRO",
    EXEMPT = "EXEMPT"
}

const SubscriptionTierPrices: Record<SubscriptionTier, number> = {
    [SubscriptionTier.BASIC]: 1500, 
    [SubscriptionTier.PRO]: 4000, 
    [SubscriptionTier.EXEMPT]: 0
};

export function getPrice(tier: SubscriptionTier): number {
    return SubscriptionTierPrices[tier];
}