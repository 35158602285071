import React, {createContext, useEffect, useState} from 'react';
import {
  createUserWithEmailAndPassword,
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  signOut as firebaseSignOut,
} from 'firebase/auth';
import subscriptionService from "../service/SubscriptionService.ts";
import identityService from "../service/IdentityService.js";
import {SubscriptionTier} from "../model/enum/subscriptionTier.ts";


const stringToSubscriptionTier = (tierString) => {
  if (!tierString) return null;
  const upperValue = tierString.toUpperCase();
  if (SubscriptionTier[upperValue]) {
    return SubscriptionTier[upperValue];
  }
  return null;
};

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [token, setToken] = useState(null);
  const [subscriptionTier, setSubscriptionTier] = useState(() => {
    const storedTier = sessionStorage.getItem('subscriptionTier');
    return stringToSubscriptionTier(storedTier);
  });

  useEffect(() => {
    const auth = getAuth();
    return onAuthStateChanged(auth, async (currentUser) => {
      setUser(currentUser);
      setLoading(false);

      if (currentUser) {
        const idToken = await currentUser.getIdToken();
        setToken(idToken);
      } else {
        setToken(null);
        sessionStorage.removeItem('subscriptionTier');
        setSubscriptionTier(null);
      }
    });
  }, []);

  const signUp = async (email, password) => {
    const userCredentialWithEmail = await createUserWithEmailAndPassword(getAuth(), email, password);
    const emailIdToken = await userCredentialWithEmail.user.getIdToken();
    setToken(emailIdToken);
    const customToken = await identityService.getCustomToken();
    const userCredential = await signInWithCustomToken(getAuth(), customToken);
    const idToken = await userCredential.user.getIdToken();
    setToken(idToken);
  };

  const login = async (email, password) => {
    const userCredentialWithEmail = await signInWithEmailAndPassword(getAuth(), email, password);
    const emailIdToken = await userCredentialWithEmail.user.getIdToken();
    setToken(emailIdToken);
    // const customToken = await identityService.getCustomToken();
    // const userCredential = await signInWithCustomToken(getAuth(), customToken);
    // const idToken = await userCredential.user.getIdToken();
    // setToken(idToken);
    const tier = await subscriptionService.getSubscriptionTier();
    const subscriptionTier = stringToSubscriptionTier(tier)
    sessionStorage.setItem('subscriptionTier', tier.toString());
    setSubscriptionTier(subscriptionTier);

  };

  const logout = () => {
    return firebaseSignOut(getAuth()).then(() => {
      setUser(null);
      setToken(null);
      setSubscriptionTier(null);
      sessionStorage.removeItem('subscriptionTier');
    });
  };

  const resetPassword = (email) => {
    return identityService.resetPassword(email);
  };

  return (
      <AuthContext.Provider value={{ user, token, subscriptionTier, signUp, login, logout, resetPassword, loading }}>
        {children}
      </AuthContext.Provider>
  );
};