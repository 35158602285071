import React from "react";
import PropTypes from "prop-types";
import { SubscriptionTier, getPrice } from "../model/enum/subscriptionTier.ts";
import { Modal } from 'antd';
import Button from './ui/Button/Button'
import Button2 from './ui/Button2/Button2';

interface UpgradeSubscriptionPopupProps {
    newPricingPlan: SubscriptionTier;
    visible: boolean;
    closePopup: () => void;
    confirmUpgrade: () => void;
}

const UpgradeSubscriptionPopup: React.FC<UpgradeSubscriptionPopupProps> = ({
    newPricingPlan,
    visible,
    closePopup,
    confirmUpgrade,
}) => {
    const newPrice = getPrice(newPricingPlan);

    return (
        <>
          <Modal
            open={visible}
            title="Abonelik Planınızı Güncelleyin"
            onOk={confirmUpgrade}
            onCancel={closePopup}
            footer={
              <div style={{ 
                display: 'flex', 
                justifyContent: 'space-between',
                width: '100%'
              }}>
                <div style={{ display: 'flex', gap: '8px' }}>
                  <Button2
                    onClick={closePopup}
                    style={{
                      fontSize: "13px",
                      width: "120px",
                      minHeight: "40px"
                    }}
                  >
                    İptal
                  </Button2>
                  <Button
                    onClick={confirmUpgrade}
                    style={{
                      fontSize: "13px",
                      width: "120px",
                      minHeight: "40px",
                      backgroundColor: "#6B1A28",
                      color: "#EFE8E1"
                    }}
                  >
                    Onayla
                  </Button>
                </div>
                <Button
                  onClick={() => window.open('https://leagleapp.com/tr/price', '_blank')}
                  style={{
                    fontSize: "13px",
                    width: "180px",
                    minHeight: "40px",
                  }}
                >
                  Planları Karşılaştır
                </Button>
              </div>
            }
          >
            <p>
                Şu anki abonelik planınız: <strong>{sessionStorage.getItem('subscriptionTier')}</strong>. 
                Planınız <strong>{newPricingPlan}</strong> olarak değiştirilecektir. Yeni planınızın aylık ücreti: <strong>₺{newPrice}</strong>.
            </p>
            <p>
                Abonelik planınızı ayda sadece bir kez değiştirebilirsiniz. Mevcut ücretsiz deneme süreniz varsa, değişiklikle birlikte sona erecektir 
                ve kartınızdan ücret tahsil edilecektir. Ancak, önceki planınızın kalan günleri boyunca yeni planınızı kullanmaya devam edebilirsiniz. 
                Kalan süre bittiğinde yeni planınızın ücreti kartınıza yansıtılacaktır.
            </p>
            <p>
                Abonelik planınız güncellendikten sonra giriş yapma sayfasına yönlendirileceksiniz. 
                Tüm planları incelemek için <a href="https://leagleapp.com/tr/price" target="_blank" rel="noopener noreferrer">bu sayfayı</a> ziyaret edebilirsiniz.
            </p>
          </Modal>
        </>
      );
};

export default UpgradeSubscriptionPopup;
