import axiosClient from "../client/LeagleClient";
import {SubscriptionDetailsResponse} from "../model/response/subscriptionDetailsResponse";
import {CancelSubscriptionResponse} from "../model/response/cancelSubscriptionResponse";
import {SubscriptionTierResponse} from "../model/response/subscriptionTierResponse";
import { UpgradeSubscriptionResponse } from "../model/response/upgradeSubscriptionResponse";

const subscriptionService = {

    async getSubscriptionTier(){
        try {
            const endpoint = `/payment/subscription/tier`;
            const response = await axiosClient.get(endpoint);
            return response.data['subscriptionTier'];
        } catch (error) {
            console.error(`Error fetching the subscription tier of the user:`, error);
            throw error;
        }
    },

    async getSubscriptionDetails(): Promise <SubscriptionDetailsResponse>{
        try {
            const endpoint = `/payment/subscription/details`;
            const response = await axiosClient.get(endpoint);
            return response.data
        } catch (error) {
            console.error(`Error getting the subscription details of the user:`, error);
            throw error;
        }
    },

    async cancelSubscription() : Promise<CancelSubscriptionResponse> {
        try {
            const endpoint = `/payment/subscription/cancel`;
            const response = await axiosClient.post(endpoint);
            return response.data
        } catch (error) {
            console.error(`Error cancelling the subscription of the user:`, error);
            throw error;
        }
    },

    async upgradeSubscription(newPricingPlan: string) : Promise<UpgradeSubscriptionResponse> {
        try {
            const endpoint = `/payment/subscription/upgrade`;
            const response = await axiosClient.post(endpoint, {
                newPricingPlan: newPricingPlan
            });
            return response.data
        } catch (error) {
            console.error(`Error upgrading the subscription of the user:`, error);
            throw error;
        }
    }
};

export default subscriptionService;