import {React, useState, useContext} from 'react'
import { useNavigate } from 'react-router-dom'
import { AuthContext } from '../contexts/AuthContext';
import {
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CFormInput,
  CInputGroup,
  CInputGroupText,
  CRow,
} from '@coreui/react'

const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const { resetPassword } = useContext(AuthContext);
    const [message, setMessage] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const handleSubmit = async (e) => {
        e.preventDefault();
        setMessage('');
        setError('');

        try {
            await resetPassword(email);
            setMessage('Parola yenileme linki email adresinize gönderildi.');
            setTimeout(() => {
                navigate('/login');
            }, 2000); // Wait for 2 seconds then navigate
        } catch (error) {
            if (error.response && error.response.status === 404) {
                setError('Bu email adresi ile kayıtlı bir kullanıcı bulunamadı.');
            } else {
                setError('Parola yenileme sırasında bir hata oluştu.');
            }
        }
    };
    

    return (
        <div className="bg-body-tertiary min-vh-100 d-flex flex-row align-items-center">
        <CContainer>
            <CRow className="justify-content-center">
            <CCol md={9} lg={7} xl={6}>
                <CCard className="mx-4">
                <CCardBody className="p-4">
                    <CForm onSubmit={handleSubmit}>
                    <h1>Parolamı Yenile</h1>
                    <p className="text-body-secondary">Parolanı yenilemek için email adresine parola yenileme linki göndereceğiz.</p>
                    <CInputGroup className="mb-3">
                        <CInputGroupText>@</CInputGroupText>
                        <CFormInput
                        type="email" 
                        value={email} 
                        onChange={e => setEmail(e.target.value)} 
                        placeholder="Email" 
                        autoComplete="email" />
                    </CInputGroup>
                    <div className="d-grid">
                        {message && <div className="alert alert-success">{message}</div>}
                        {error && <div className="alert alert-danger">{error}</div>}
                    </div>
                    <div className="d-grid">
                        <CButton type="submit" color="success">Parolamı Yenile</CButton>
                    </div>
                    </CForm>
                    
                </CCardBody>
                
                </CCard>
            </CCol>
            </CRow>
        </CContainer>
        </div>
    )
}

export default ForgotPassword;
