import React from "react";
import { CContainer, CButton } from "@coreui/react";
import {
  HukukDairesiSelector,
  CezaDairesiSelector,
  DateFilter,
  MevzuatCategorySelector,
} from "../components/Filtering";
import Button from "./ui/Button/Button";

const MevzuatFilterPanel = ({
  startDateDay,
  setStartDateDay,
  startDateMonth,
  setStartDateMonth,
  startDateYear,
  setStartDateYear,
  endDateDay,
  setEndDateDay,
  endDateMonth,
  setEndDateMonth,
  endDateYear,
  setEndDateYear,
  selectedMevzuatCategory,
  setSelectedMevzuatCategory,
  filterResults,
  sortByDate,
  sortByRelevancy,
}) => {
  return (
    <>
      <div
        style={{
          gap: "8px",
          marginBottom: "10px",
          paddingLeft: "8px",
          paddingRight: "8px",
        }}
        className="d-flex justify-content-center align-items-center"
      >
        <Button style={{ width: "100%" }} color="primary" onClick={sortByDate}>
          Tarihe Göre Sırala
        </Button>
        <Button
          style={{ width: "100%" }}
          color="primary"
          onClick={sortByRelevancy}
        >
          İlgiye Göre Sırala
        </Button>
      </div>
      <DateFilter
        startDateDay={startDateDay}
        setStartDateDay={setStartDateDay}
        startDateMonth={startDateMonth}
        setStartDateMonth={setStartDateMonth}
        startDateYear={startDateYear}
        setStartDateYear={setStartDateYear}
        endDateDay={endDateDay}
        setEndDateDay={setEndDateDay}
        endDateMonth={endDateMonth}
        setEndDateMonth={setEndDateMonth}
        endDateYear={endDateYear}
        setEndDateYear={setEndDateYear}
      />
      
      <MevzuatCategorySelector
        selectedCategory={selectedMevzuatCategory}
        setSelectedCategory={setSelectedMevzuatCategory}
      />
      

      <CContainer style={{ display: "flex", justifyContent: "center" }}>
        <Button
          style={{ margin: "10px" }}
          onClick={filterResults}
        >
          Filtrele
        </Button>
      </CContainer>
    </>
  );
};

export default MevzuatFilterPanel;
